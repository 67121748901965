// Entry point for the build script in your package.json
var tournamentSelects = []

window.initTournamentUI = () => {
  let removedEntries = []

  document.querySelectorAll(".tournament-select").forEach((element) => {
    let settings = {
      maxItems: 1,
      persist: false,
      loadThrottle: null
    };
    tournamentSelects.push(element);

    // If a name is selected, disable it in all the other selects
    element.addEventListener("change", (event) => {
      const newValue = event.target.value;
      const text = element.options[element.selectedIndex].text;
      removedEntries.push({ text, value: newValue });

      // If there is a cached value, enable it in the other selects
      if (element.dataset.cachedOldValue) {
        const entryDetails = removedEntries.find(entry => entry.value === element.dataset.cachedOldValue)
        tournamentSelects.forEach((selectElement) => {
          if (selectElement.id == element.id) {
            return
          }

          const options = selectElement.options;
          // Insert it alphabetically
          let nextOption = null
          for (let i = 0; i < options.length; i++) {
            if (options[i].text > entryDetails.text) {
              nextOption = options[i]
              break
            }
          }


          if (nextOption) {
            options.add(new Option(entryDetails.text, entryDetails.value), nextOption)
          } else {
            options.add(new Option(entryDetails.text, entryDetails.value))
          }
        });
      }

      // Remove the selected option in all the other selects
      tournamentSelects.forEach((selectElement) => {
        if (selectElement.id == element.id) {
          return
        }
        const options = selectElement.options;
        for (let i = 0; i < options.length; i++) {
          if (options[i].value === newValue) {
            options[i].remove();
          }
        }
      });

      // Store the new value in a data attribute
      element.dataset.cachedOldValue = newValue;
    });
  });
};
